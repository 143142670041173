import clsx from 'clsx';
import __isEmpty from 'lodash/isEmpty';
import Image from 'next/image';
import { ReactSVG } from 'react-svg';
import { dataResources } from 'utils/constants';
import styles from './icona.module.scss';
const { basePath } = dataResources;

export default function Icona(props) {
    const { icona, alt, isBlanc, className, inline, mida, ariaHidden, path } = props;

    const amplada = mida && !isNaN(mida) ? parseInt(mida) : 24;

    const styleIcona = clsx({
        [styles.icona]: true,
        [className]: !__isEmpty(className)
    });

    const color = isBlanc ? 'blanc' : 'negre';
    const src = `${basePath}/_resources/icones/${path}/${color}/${icona}.svg?v=1.2409.6`;

    const imageLoader = ({ src }) => {
        return `${src}`;
    };

    if (inline) {
        return (
            <ReactSVG
                data-icon={icona}
                className={styleIcona}
                beforeInjection={(svg) => {
                    svg.setAttribute('width', '100%');
                    svg.setAttribute('height', '100%');
                }}
                src={src}
                wrapper='svg'
                aria-label={alt}
                aria-hidden={ariaHidden}
                data-testid={`icona-${icona}`}
            />
        );
    } else {
        return (
            <span className={styleIcona}>
                <Image
                    loader={imageLoader}
                    width={amplada}
                    height={amplada}
                    unoptimized={true}
                    alt={alt}
                    src={`${basePath}/_resources/icones/${path}/${color}/${icona}.svg?v=1.2409.6`}
                    aria-hidden={ariaHidden}
                    data-testid={`icona-${icona}`}
                />
            </span>
        );
    }
}

Icona.defaultProps = {
    icona: '',
    alt: '',
    isBlanc: false,
    inline: false,
    className: '',
    path: 'noticies'
};
